import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NonAuthLayout from "../NonAuthLayout";
import AuthLayout from "../AuthLayout";
import { privateRoutes, publicRoutes } from "../../routes";
import Loader from "../Loader";
const NotFound = React.lazy(() => import("../../pages/NotFound"));

const AppRoutes = () => {
  const isAuthenticated = () => {
    const token = localStorage.getItem("noticeToken");
    const userData = localStorage.getItem("noticeUser");
    return !!(token && userData);
  };

  const getFilteredRoutes = () => {
    const userData = localStorage.getItem("noticeUser");
    if (!userData) return [];

    return privateRoutes?.filter((route) => {
      if (route?.defaultAccess) {
        return true;
      }
      return route?.access?.includes(userData);
    });
  };
  return (
    <Routes>
      {publicRoutes.map(
        ({ path, component: Component, exact, title, description }, index) => {
          return (
            <Route
              key={index}
              path={path}
              exact={exact}
              element={
                <Suspense fallback={<Loader />}>
                  <NonAuthLayout>
                    <Component title={title} description={description} />
                  </NonAuthLayout>
                </Suspense>
              }
            />
          );
        }
      )}

      <Route path="/" exact element={<AuthLayout />}>
        {getFilteredRoutes()?.map(
          (
            { path, component: Component, exact, title, description },
            index
          ) => (
            <Route
              key={index}
              path={path}
              exact={exact}
              element={
                isAuthenticated() ? (
                  <Suspense fallback={<Loader />}>
                    <Component title={title} description={description} />
                  </Suspense>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          )
        )}
      </Route>
      <Route
        path="*"
        element={
          isAuthenticated() ? (
            <Suspense fallback={<Loader />}>
              <NotFound />
            </Suspense>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import axios from "axios";
import { ENDPOINT_DEV } from "../utils/constants";

// Token management
const TOKEN_KEY = "noticeToken";
const getToken = () => localStorage.getItem(TOKEN_KEY);
const removeToken = () => localStorage.removeItem(TOKEN_KEY);

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: ENDPOINT_DEV,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
  withCredentials: true,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Skip token check for login requests
    if (config.url?.includes("/login")) {
      return config;
    }

    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status;

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axiosInstance.post("/reset-token");
        const newAccessToken = response.data.accessToken;

        localStorage.setItem(TOKEN_KEY, newAccessToken);

        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        removeToken();
        window.location.href = "/login";
        return Promise.reject(new Error("Authentication failed"));
      }
    }

    if (status === 403) {
      setTimeout(() => {
        removeToken();
        window.location.href = "/login";
      }, 3000);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

// Enhanced API request function
const apiRequest = async (method, url, data = {}, config = {}) => {
  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      ...config,
    });

    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

// API method exports
export const apiGet = (url, config) => apiRequest("GET", url, {}, config);
export const apiPost = (url, data, config) =>
  apiRequest("POST", url, data, config);
export const apiPut = (url, data, config) =>
  apiRequest("PUT", url, data, config);
export const apiDelete = (url, config) => apiRequest("DELETE", url, {}, config);

export const apiUpload = (url, formData, config = {}) => {
  const headers = {
    ...config.headers,
    "Content-Type": "multipart/form-data",
  };
  return apiRequest("POST", url, formData, { ...config, headers });
};
export default axiosInstance;

import React from "react";

const Login = React.lazy(() => import("../pages/Login"));
const PDFTool = React.lazy(() => import("../pages/PdfTool"));
const Users = React.lazy(() => import("../pages/Users"));
const UserDetail = React.lazy(() => import("../pages/UserDetail"));
const ChangePassword = React.lazy(() => import("../pages/ChangePassword"));

// Public routes array
export const publicRoutes = [
  {
    path: "/login",
    component: Login,
    exact: true,
    title: "GS Notice Tool | Login",
    description: "Convert notice file to text which can be copied.",
  },
];

// Private routes array
export const privateRoutes = [
  {
    path: "/",
    component: PDFTool,
    exact: true,
    access: ["admin", "user"],
    title: "GS Notice Tool",
    description: "Convert notice file to text which can be copied.",
  },
  {
    path: "/users",
    component: Users,
    exact: true,
    access: ["admin"],
    title: "GS Notice Tool | Users",
    description: "Convert notice file to text which can be copied.",
  },
  {
    path: "/users/:userId",
    component: UserDetail,
    exact: true,
    access: ["admin"],
    title: "GS Notice Tool | User Details",
    description: "Convert notice file to text which can be copied.",
  },
  {
    path: "/change-password",
    component: ChangePassword,
    exact: true,
    access: ["admin", "user"],
    title: "GS Notice Tool | Change Password",
    description: "Convert notice file to text which can be copied.",
  },
];

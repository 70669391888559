import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../../redux-toolkit/reducers/userReducer";

const AuthLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPasswordChange } = useSelector((state) => state.user);
  const noticeToken = localStorage.getItem("noticeToken") || null;
  const noticeUser = localStorage.getItem("noticeUser") || null;
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeUser = async () => {
      const token = localStorage.getItem("noticeToken");
      if (token) {
        await dispatch(getMe());
      }
      setIsInitialized(true);
    };
    initializeUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isInitialized &&
      isPasswordChange === false &&
      noticeToken &&
      noticeUser &&
      noticeUser === "user"
    ) {
      navigate("/change-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, noticeToken, noticeUser, isPasswordChange, navigate]);

  // If user is not logged in, redirect to login
  if (!(noticeToken && noticeUser)) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div>
      <Header />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default AuthLayout;

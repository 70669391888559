import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getMeAPI,
  updatePasswordAPI,
  uploadDocumentAPI,
  createUserAPI,
  deleteUserAPI,
  resetPasswordAPI,
  updateUserAPI,
  getUserListAPI,
  loginAPI,
  logoutAPI,
  getUserByIdAPI,
} from "../../apis/userAPI";

const initialState = {
  isPasswordChange: false,

  getMe: {},
  getMeLoading: false,
  getMeError: null,

  userData: null,
  loading: false,
  error: null,

  usersList: {
    users: [],
    metadata: {
      currentPage: 1,
      totalPages: 0,
      usersPerPage: 10,
    },
  },
  usersLoading: false,
  usersError: null,

  userDetails: {},
  userDetailsLoading: false,
  userDetailsError: null,

  selectedUser: null,

  resetPassword: {},
  resetPasswordLoading: false,
  resetPasswordError: null,

  deleteUser: {},
  deleteUserLoading: false,
  deleteUserError: null,

  createUser: {},
  createUserLoading: false,
  createUserError: null,

  changePassword: {},
  changePasswordLoading: false,
  changePasswordError: null,

  googleCloudData: "",
  documentTextInput: "",
  documentAIData: [],
  uploadDocumentLoading: false,
  uploadDocumentError: null,

  updateUser: {},
  updateUserLoading: false,
  updateUserError: null,

  logout: {},

  userByIdData: {},
  userByIdLoading: false,
  userByIdError: null,
};

export const login = createAsyncThunk(
  "user/login",
  async (userData, { rejectWithValue }) => {
    try {
      return await loginAPI(userData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      return await logoutAPI();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserList = createAsyncThunk(
  "user/getUserList",
  async (params, { rejectWithValue }) => {
    try {
      return await getUserListAPI(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserById = createAsyncThunk(
  "user/updateUserById",
  async (userData, { rejectWithValue }) => {
    try {
      return await updateUserAPI(userData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (userData, { rejectWithValue }) => {
    try {
      return await createUserAPI(userData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "user/resetPassword",
  async (resetPasswordData, { rejectWithValue }) => {
    try {
      return await resetPasswordAPI(resetPasswordData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserById = createAsyncThunk(
  "user/deleteUserById",
  async (userId, { rejectWithValue }) => {
    try {
      return await deleteUserAPI(userId);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getMe = createAsyncThunk(
  "user/getMe",
  async (_, { rejectWithValue }) => {
    try {
      return await getMeAPI();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (passwordData, { rejectWithValue }) => {
    try {
      return await updatePasswordAPI(passwordData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "user/uploadDocument",
  async (documentData, { rejectWithValue }) => {
    try {
      return await uploadDocumentAPI(documentData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (userData, { rejectWithValue }) => {
    try {
      return await getUserByIdAPI(userData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setPasswordChangeStatus: (state, action) => {
      state.isPasswordChange = action.payload;
    },
    clearUploadDocument: (state) => {
      state.googleCloudData = "";
      state.documentTextInput = "";
      state.documentAIData = [];
    },
    clearChangePassword: (state) => {
      state.changePassword = {};
    },
    clearDeleteUserByIdState: (state) => {
      state.deleteUser = {};
      state.deleteUserError = null;
    },
    clearCreateUserState: (state) => {
      state.createUser = {};
      state.createUserError = null;
    },
    clearEditUserState: (state) => {
      state.updateUser = {};
      state.updateUserError = null;
    },
    clearResetPasswordState: (state) => {
      state.resetPassword = {};
      state.resetPasswordError = null;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        if (action.payload && action.payload?.success) {
          state.userData = action.payload?.data;
          state.isPasswordChange =
            action.payload?.data?.role === "admin"
              ? true
              : action.payload?.data?.isPasswordChange || false;
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(logout.pending, (state) => {})
      .addCase(logout.fulfilled, (state, action) => {
        if (action.payload && action.payload?.success) {
          state.isPasswordChange = false;
          state.logout = action.payload;
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(logout.rejected, (state, action) => {
        toast.error(action.payload?.message);
      })
      .addCase(getMe.pending, (state) => {
        state.getMeLoading = true;
        state.getMeError = null;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.getMeLoading = false;
        state.getMeError = null;
        state.getMe = action.payload?.data;
        state.isPasswordChange =
          action.payload?.data?.role === "admin"
            ? true
            : action.payload?.data?.isPasswordChange || false;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.getMeLoading = false;
        state.getMeError = action.payload;
      })
      .addCase(getUserList.pending, (state) => {
        state.usersLoading = true;
        state.usersError = null;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.usersError = null;
        state.usersList = action.payload?.data;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserError = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(getUserById.pending, (state) => {
        state.userByIdLoading = true;
        state.userByIdError = null;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.userByIdLoading = false;
        state.userByIdError = null;
        state.userByIdData = action.payload?.data;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.userByIdLoading = false;
        state.userByIdError = action.payload;
      })
      .addCase(updateUserById.pending, (state) => {
        state.updateUserLoading = true;
        state.updateUserError = null;
      })
      .addCase(updateUserById.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserError = null;
        if (action.payload && action.payload?.success) {
          state.updateUser = action.payload;
          const updatedUser = action.payload?.data;
          const userIndex = state.usersList?.users.findIndex(
            (user) => user?._id === updatedUser?._id
          );

          if (userIndex !== -1) {
            state.usersList.users[userIndex] = {
              ...state.usersList.users[userIndex],
              ...updatedUser,
            };
          }
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(updateUserById.rejected, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserError = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(createUser.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserLoading = false;
        state.createUserError = null;
        if (action.payload && action.payload?.success) {
          state.createUser = action.payload;
          state.usersList.users = [
            action.payload?.data,
            ...state.usersList.users,
          ];
          state.usersList.metadata.totalUsers += 1;
          state.usersList.metadata.totalPages = Math.ceil(
            state.usersList.metadata.totalUsers /
              state.usersList.metadata.usersPerPage
          );
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserLoading = false;
        state.createUserError = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(resetUserPassword.pending, (state) => {
        state.resetPasswordLoading = true;
        state.resetPasswordError = null;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.resetPasswordLoading = false;
        state.resetPasswordError = null;
        if (action.payload && action.payload?.success) {
          state.resetPassword = action.payload;
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.resetPasswordLoading = false;
        state.resetPasswordError = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(deleteUserById.pending, (state) => {
        state.deleteUserLoading = true;
        state.deleteUserError = null;
      })
      .addCase(deleteUserById.fulfilled, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserError = null;
        if (action.payload && action.payload?.success) {
          state.deleteUser = action.payload;
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(deleteUserById.rejected, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserError = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(changePassword.pending, (state) => {
        state.changePasswordLoading = true;
        state.changePasswordError = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordError = null;
        if (action.payload && action.payload?.success) {
          state.changePassword = action.payload;
          state.isPasswordChange = true;
          toast.success(action.payload?.message);
        }
        if (action?.payload && !action?.payload?.success) {
          toast.error(action.payload?.message);
        }
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordError = action.payload;
        toast.error(action.payload?.message);
      })
      .addCase(uploadDocument.pending, (state) => {
        state.uploadDocumentLoading = true;
        state.uploadDocumentError = null;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.uploadDocumentLoading = false;
        state.uploadDocumentError = null;
        if (action.payload?.data?.textInput && action.payload.success) {
          state.documentTextInput = action.payload?.data?.textInput;
          state.googleCloudData = "";
          state.documentAIData = [];
          toast.success(action.payload?.message);
        }
        if (action.payload?.data?.non_unicode && action.payload.success) {
          state.googleCloudData = action.payload?.data?.non_unicode;
          state.documentTextInput = "";
          state.documentAIData = [];
          toast.success(action.payload?.message);
        }
        if (action.payload?.data?.documentAIData && action.payload.success) {
          state.documentAIData = action.payload?.data?.documentAIData;
          state.documentTextInput = "";
          state.googleCloudData = "";
          toast.success(action.payload?.message);
        }

        if (action.payload && !action.payload.success) {
          state.documentTextInput = "";
          state.googleCloudData = "";
          state.documentAIData = [];
          toast.error(action.payload?.message);
        }
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.uploadDocumentLoading = false;
        state.uploadDocumentError = action.payload;
        toast.error(action.payload?.message);
      });
  },
});
// Actions
export const {
  setSelectedUser,
  setPasswordChangeStatus,
  clearUploadDocument,
  clearChangePassword,
  clearDeleteUserByIdState,
  clearCreateUserState,
  clearEditUserState,
  clearResetPasswordState,
} = userSlice.actions;

// Reducer
export default userSlice.reducer;

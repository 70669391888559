import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Avatar,
  Popover,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux-toolkit/reducers/userReducer";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { getMe, logout: logoutData } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const role = localStorage.getItem("noticeUser") || null;
  const email = getMe?.email;
  useEffect(() => {
    if (logoutData?.success) {
      localStorage.removeItem("noticeUser");
      localStorage.removeItem("noticeToken");
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutData]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handlePopoverClose();
    dispatch(logout());
  };

  const handleChangePassword = () => {
    navigate("/change-password");
    handlePopoverClose();
  };

  const isCurrentPath = (path) => location.pathname === path;

  const menuItems = [
    { text: "Home", path: "/", icon: <HomeIcon />, show: true },
    {
      text: "Users",
      path: "/users",
      icon: <GroupIcon />,
      show: role === "admin",
    },
  ];

  return (
    <>
      <AppBar
        position="static"
        elevation={1}
        sx={{
          background: "#103784",
          color: "white",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* Menu Items on the Left Side */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {menuItems.map(
              (item) =>
                item.show && (
                  <Button
                    key={item.text}
                    onClick={() => navigate(item.path)}
                    startIcon={item.icon}
                    sx={{
                      color: isCurrentPath(item.path)
                        ? "white"
                        : "rgba(255, 255, 255, 0.8)",
                      fontWeight: isCurrentPath(item.path) ? "bold" : "normal",
                      backgroundColor: isCurrentPath(item.path)
                        ? "rgba(255, 255, 255, 0.2)"
                        : "transparent",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                      },
                    }}
                  >
                    {item.text}
                  </Button>
                )
            )}
          </Box>

          {/* Avatar and Popover */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              onClick={handlePopoverOpen}
              sx={{
                bgcolor: theme.palette.secondary.main,
                cursor: "pointer",
              }}
            >
              {email?.charAt(0).toUpperCase()}
            </Avatar>

            {email && (
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ p: 2, minWidth: 200 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {email}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <List>
                    <ListItem button onClick={handleChangePassword}>
                      <ListItemIcon>
                        <LockIcon />
                      </ListItemIcon>
                      <ListItemText primary="Change Password" />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutIcon color="error" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Box>
              </Popover>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;

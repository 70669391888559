import { apiDelete, apiGet, apiPost, apiPut, apiUpload } from "./axios";
import { ENDPOINT_DEV } from "../utils/constants";

export const getMeAPI = async () => {
  return await apiGet(ENDPOINT_DEV + "getMe");
};

export const createUserAPI = async (userData) => {
  return await apiPost(ENDPOINT_DEV + "user", userData);
};

export const deleteUserAPI = async (userId) => {
  return await apiDelete(ENDPOINT_DEV + `users/${userId}`);
};

export const getUserListAPI = async (params) => {
  const { page, limit } = params;
  return await apiGet(ENDPOINT_DEV + `users?page=${page}&limit=${limit}`);
};

export const getUserByIdAPI = async (params) => {
  const { userId } = params;
  return await apiGet(ENDPOINT_DEV + `users/${userId}`);
};

export const resetPasswordAPI = async (resetPasswordData) => {
  return await apiPost(ENDPOINT_DEV + "reset-password", resetPasswordData);
};
export const updatePasswordAPI = async (userPasswordData) => {
  return await apiPost(ENDPOINT_DEV + "change-password", userPasswordData);
};

export const uploadDocumentAPI = async (documentData) => {
  return await apiUpload(ENDPOINT_DEV + "upload-document", documentData);
};

export const updateUserAPI = async (userData) => {
  return await apiPut(
    ENDPOINT_DEV + `users/${userData?.userId}`,
    userData?.updateUserData
  );
};

export const loginAPI = async (userData) => {
  return await apiPost(ENDPOINT_DEV + "login", userData);
};

export const logoutAPI = async (userData) => {
  return await apiPost(ENDPOINT_DEV + "logout", userData);
};

import React from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux-toolkit/store/config";
import AppRoutes from "./components/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "hsla(220, 78%, 29%, 1)",
    },
    black: {
      main: "hsla(0, 0%, 0%, 1)",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <ToastContainer
              position="top-right"
              autoClose={4000}
              pauseOnHover={false}
            />
            <AppRoutes />
          </Router>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React from "react";
import LoaderGif from "../../assets/LoaderGiff.svg";
import "./Loader.css";

export default function Loader() {
  return (
    <div className="loader-container">
      <img src={LoaderGif} alt="Loading" width="100px" height="100px"/>
    </div>
  );
}

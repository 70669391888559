export const paginationConstant = {
  skip: 0,
  page: 1,
  limit: 10,
};

const BASE_ENDPOINT = process.env.REACT_APP_API_URL;
export const ENDPOINT_DEV = `${BASE_ENDPOINT}/api/`;

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
